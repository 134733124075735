// import {createMuiTheme} from '@material-ui/core'
const size = {
  xs: '370px',
  smp: '450px',
  sm: '600px',
  md: '960px',
  l: '1080px',
  lg: '1280px',
  sl: '1600px',
  xl: '1920px',
}

export const theme = {
  colors: {
    body: '#FFFFFF',
    color: '#E69559',
    orangeDark: "#E99349",
    text: '#183264',
    gray: "#F0F0F0"
  },
  breaks: {
    up: bp => `(min-width: ${size[bp]})`,
    down: bp => `(max-width: ${size[bp]})`,
    bewteen: (bp1, bp2) => `(min-width: ${size[bp1]}) and (max-width: ${size[bp2]})`,
    only: bp => `(width: ${size[bp]})`
  },
  maxWidth: '1280px',
  maxWidthMd: '960px'
}

// console.log(theme);