import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import gsap from 'gsap'

import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);
 
const Container = styled.div`
 display: flex;
 height: 100%;
 padding: 10rem 0;
 flex-direction: column;
 justify-content: center;
 align-items: center;
`

const Item = styled.div`
  display: block;
  color: ${props => props.theme.colors.body};
  text-transform: uppercase;
  font-weight: 300;
  font-size: 3rem;
  transition: all .2s ease;
  margin: 4rem 0;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.color};
    transform: scale(1.07);
  }
  
  @media ${props => props.theme.breaks.down('lg')} {
     font-size: 3rem;
  }
`

export default function NavItems ({ handleCheck }) {

  const [active, setActive] = useState("")

  const handleActive = (val) => {
    setActive(val)
    handleCheck()
    gsap.to(window, {scrollTo: {y: `#${val}`, offsetY: 0}, duration: 1, ease: "ease.out"})
  }

  const links = [
    {
      name: "Services",
      path: "services"
    },
    {
      name: "About Us",
      path: "about-us"
    },
    {
      name: "Contact Us",
      path: "contact-us"
    },
  ]
 
 return (
 <Container>
   {links && links.map(({name, path}) => 
    <Item key={name} onClick={() => handleActive(`${path}`)}>{name}</Item>
   )}
   
 </Container>
 )
}