import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';

import gsap from 'gsap';
import { theme } from "../../utils/theme";
import NavItem from './navItems';


const NavContainer = styled.div`
   position: relative;
   height: 20px;
   width: 20px;
    
    .nav_button {
        cursor: pointer;
        display: inline-block;
        height: 30px;
        width: 30px;
        text-align: center;
        position: absolute; 
        z-index: 1000;
    }
    
    #nav_toggle {
        display: none;
    }
    
    #nav_toggle:checked ~ .nav{
        opacity: 1;
        width: 100%;
    }

    #nav_toggle:checked + .nav_button .nav_icon#top-bar2{
        background-color: transparent !important;
    }
    
    #nav_toggle:checked + .nav_button .nav_icon#top-bar1 {
        top: 9px;
        transform: rotate(135deg);
        background-color: ${props => props.theme.colors.color};
    }
    
    #nav_toggle:checked + .nav_button .nav_icon#top-bar3{
        top: 9px;
        transform: rotate(-135deg);
        background-color: ${props => props.theme.colors.color};
    }
    
    .nav_icon {
        position: absolute;
        /* margin-top: 15px; */
        top: 0;
        left: .2rem;
    
        &
        /* &::before,
        &::after  */
        {
            display: inline-block;
            height: 3px;
            width: 30px;
            border-radius: 50px;
            background-color: ${props => props.theme.colors.text};
            transition: all .2s;
        }

        &#top-bar2 {
            top: 9px;
        }

        &#top-bar3 {
            top: 18px;
        }
        
        
    }
    .nav_button:hover .nav_icon#top-bar1 {
        top: -2px;
    }
    .nav_button:hover .nav_icon#top-bar3 {
        top: 20px;
    }
    
`

const NavBackground = styled.div`

  background-color: transparent;
  height: 5px;
  width: 5px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 50;

  // transition: transform .4s;
`

const NavItems = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: transparent;
  z-index: 55;
`
 
export default function Nav ({color}) {

    const nb = useRef()
    const n = useRef()

    const [checked, setChecked] = useState(false);

    const handleCheck = () => {
        setChecked(prev => !prev);
    } 

    useEffect(() => {
        const tl = gsap.timeline()

        let width = window.innerWidth < 960 ? "100%" : "35%";

        if (checked) {
          tl.addLabel("open-nav")
          .to(nb.current, {width: width, height: "100vh", backgroundColor: theme.colors.text, duration: .4}, 'open-nav')
          .to(n.current, {autoAlpha: 1, width: '100%', duration: .2, delay: .2}, 'open-nav')
        } else {
        //  document.getElementById("nav_toggle").checked = false
          tl.addLabel("close-nav")
          .to(nb.current, {width: 5, height: 5, backgroundColor: "transparent", duration: .4}, 'close-nav')
          .to(n.current, {autoAlpha: 0, width: 0, duration: .2}, 'close-nav')
        }
    }, [checked])
 
 return (
  <NavContainer color={color}>
     <input type="checkbox" onChange={handleCheck} id="nav_toggle" checked={checked} />
    <label htmlFor="nav_toggle" className="nav_button">
        <span className="nav_icon" id="top-bar1">&nbsp;</span>
        <span className="nav_icon" id="top-bar2">&nbsp;</span>
        <span className="nav_icon" id="top-bar3">&nbsp;</span>
    </label>

    <NavBackground ref={nb} className="nav_bg">
      <NavItems ref={n}>
        <NavItem handleCheck={handleCheck} />
      </NavItems>
    </NavBackground>

    
    
  </NavContainer>
 )
}