import React, { useState } from 'react'
import styled, {css} from 'styled-components'

import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);


const Container = styled.div`
  width: 45rem;
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breaks.down('md')} {
    display: none;
  }
`

const LinkItem = styled.div`
  display: block;
  width: max-content;
  font-size: 1.8rem;
  text-transform: uppercase;
  padding-bottom: 1rem;
  border-bottom: ${props => props.act ? `3px solid ${props.theme.colors.color}` : "none"};
  cursor: pointer;


  &:hover {
    border-bottom: 3px solid ${props => props.theme.colors.color};
  } 
  
` 
 
export default () => {

  const [active, setActive] = useState("")

  const handleActive = (val) => {
    setActive(val)
    gsap.to(window, {scrollTo: {y: `#${val}`, offsetY: 0}, duration: 1.8, ease: "power1.inOut"})
  }
 
 return (
  <Container>
    {console.log(active)}
    <LinkItem act={active === "services"} onClick={() => handleActive("services")}>Services</LinkItem>
    <LinkItem act={active === "about-us"} onClick={() => handleActive("about-us")}>About Us</LinkItem>
    <LinkItem act={active === "contact-us"} onClick={() => handleActive("contact-us")}>Contact Us</LinkItem>
  </Container>
 )
}