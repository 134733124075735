import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1450px;
  /* max-width: 1300px; */
  margin: 0 auto;
  position: relative;
  padding-bottom: 0;
  

  ${props => 
    props.centered && 
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `}

  @media ${props => props.theme.breaks.down('sl')} {
    max-width: ${props => props.theme.maxWidth};
  }

  @media ${props => props.theme.breaks.down('lg')} {
    max-width: 1180px;
    padding: 0 2%;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding: 0 4%;
  }
`

export default function RowWrapper({children, centered, className}) {
  return (
    <Wrapper className={className} centered={centered}>{children}</Wrapper>
  )
}