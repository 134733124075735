import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'

import Logo from '../images/urbo-logo.png';

import RowWrapper from '../utils/rowWrapper';

import Nav from './nav';
import MobileNav from '../components/nav/nav';

const Header = styled.div`
  height: 18rem;
  position: absolute;
  width: 100%;
  z-index: 100;
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5rem 5rem 1rem 5rem;

  @media ${props => props.theme.breaks.down('md')} {
    padding: 5rem 2rem 1rem 2rem;
  }
`


const MobileNavContainer = styled.div`
  width: 1.8rem;

  @media ${props => props.theme.breaks.up('md')} {
    display: none;
  }
`

const LogoContainer = styled(Link)`
  display: block;
  height: 9.5rem;

  img {
      width: 100%;
      object-fit: contain;
    }
  
  @media ${props => props.theme.breaks.down('md')} {
    height: 4.5rem;
    width: 8rem;
    
  }
`

const NavContainer = styled.div`
 
`
 
export default () => {
 
 return (
  <Header>
    <RowWrapper>
      <Flex>
        <LogoContainer to="/">
          <img src={Logo} alt="" />
        </LogoContainer>

        <NavContainer>
          <Nav />
        </NavContainer>

        <MobileNavContainer>
          <MobileNav />
        </MobileNavContainer>
      </Flex>

    </RowWrapper>
  </Header>
 )
}